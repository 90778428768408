<template>
  <div class="data-required">
    <v-row class="py-5">
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-text-field
            v-model="employee.name"
            label="Nama"
            outlined
            type="text"
            dense
            :rules="[validation.required]"
            @change="handleChangeName($event)"
          ></v-text-field>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-text-field
            v-if="error"
            v-model="employee.username"
            label="Username"
            outlined
            type="text"
            dense
            :rules="[validation.required]"
            :hint="hint"
            persistent-hint
            error
            @change="checkUsername($event)"
          ></v-text-field>
          <v-text-field
            v-else
            v-model="employee.username"
            label="Username"
            outlined
            type="text"
            dense
            :rules="[validation.required]"
            :hint="hint"
            persistent-hint
            @change="checkUsername($event)"
          ></v-text-field>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-autocomplete
            v-model="employee.status_work"
            label="Posisi"
            outlined
            dense
            item-text="name"
            :items="statusWorks"
            clearable
            @change="handleOther(selected,statusWork)"
          ></v-autocomplete>
        </div>
        <div>
          <v-text-field
            v-if="isHide"
            v-model="employee.status_work"
            label="Ketik posisi lain"
            outlined
            dense
          >
          </v-text-field>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-text-field
            v-model="employee.password"
            label="Password"
            :append-icon="show ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
            :type="show ? 'text' : 'password'"
            outlined
            dense
            @click:append="show = !show"
          ></v-text-field>
        </div>
      </v-col>
      <div>
        <div class="ml-4 d-flex align-center">
          Moderator Forum : <v-switch
            v-model="employee.is_moderator"
            class="ml-4"
          ></v-switch>
        </div>
      </div>
    </v-row>
  </div>
</template>

<script>
import randomString from '@/utils/random-string/randomString'
import { integerValidator, required } from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'

export default {
  name: 'DataRequired',
  props: {
    dataemployee: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      error: false,
      hint: '',
      dialog: '',
      show: false,
      isHide: false,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validation: {
        required,
        integerValidator,
      },
      statusWorks: [
        'Guru',
        'Guru BK',
        'Kepala Sekolah',
        'Security',
        'Petugas Kebersihan',
        'Tata Usaha',
        'Teknisi',
        'Laboran',
        'Kepala Sekolah',
        'Tenaga Perpustakaan',
        'Lainnya',
      ],
      statusWork: '',
      employee: {
        name: '',
        username: '',
        password: '',
        is_moderator: '',
      },
      selected: '',
      formValid: false,
    }
  },
  watch: {
    dataemployee: {
      handler() {
        this.assignValue()
      },
    },
    employee: {
      handler() {
        const valid = []
        const requiredField = ['name', 'username', 'status_work', 'password']
        Object.entries(this.employee).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
        this.$emit('change-valid')
      },
      deep: true,
    },
  },
  mounted() {
    this.assignValue()
  },
  methods: {
    assignValue() {
      this.employee = { ...this.dataemployee }
    },
    async checkUsername(username) {
      await this.$services.AuthServices.checkUsername(username).then(
        ({ data }) => {
          this.error = false
          this.hint = data.result.message
        },
        err => {
          this.error = true
          this.hint = err.message
          console.error(err)
        },
      )
    },
    handleChangeName() {
      if (this.employee.name.length > 3) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(async () => {
          const randomUsername = randomString(this.employee.name)
          await this.checkUsername(randomUsername)
          this.employee.username = randomUsername
        }, 500)
      }
    },
    handleOther(selected, statusWork) {
      if (statusWork === 'Lainnya') {
        this.isHide = true
      } else {
        this.isHide = false
      }
      // this.statusWorks.forEach(el => {
      // })
    },
  },
}
</script>

<style>
</style>
