<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :total-items="totalItems"
          :headers="headers"
          :items="employees"
          :search="search"
          :import-export="importExport"
          title="Pegawai"
          subtitle="Halaman untuk menambahkan data pegawai"
          add-btn="Tambah Data"
          :is-loading="isLoadingData"
          :is-loading-button="isLoadingButton"
          :total-pages.sync="totalPages"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @delete-item="confirmDestroy"
          @change-page="paginationHandler"
          @show-image="showImage"
          @live-search="searchHandler"
          @import-item="showFormImport"
          @export-item="exportHandler"
          @show-detail="detailCertificate"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="880"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!valueCheck"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Pegawai': 'Edit Pegawai'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template
        v-slot:body
      >
        <v-tabs
          v-model="tab"
          show-arrows
          fixed-tabs
        >
          <v-tab
            v-for="tabItem in tabs"
            :key="tabItem.title"
          >
            <span>{{ tabItem.title }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="tab"
          class="pt-6 pb-0"
        >
          <v-tab-item>
            <data-required
              v-if="dialog == 'edit'"
              ref="requiredData"
              :eager="true"
              :dataemployee.sync="updateEmployeeReq"
              @change-valid="changeValid"
            ></data-required>
            <data-required
              v-if="dialog == 'add'"
              ref="requiredData"
              :eager="true"
              :dataemployee.sync="updateEmployeeReq"
              @change-valid="changeValid"
            ></data-required>
          </v-tab-item>
          <v-tab-item>
            <data-other
              v-if="dialog == 'edit'"
              ref="otherData"
              :dataemployee.sync="updateEmployee"
            ></data-other>
            <data-other
              v-if="dialog == 'add'"
              ref="otherData"
              :dataemployee.sync="updateEmployee"
            ></data-other>
          </v-tab-item>
        </v-tabs-items>
      </template>
    </ModalDialog>
    <ModalDialog
      :visible="modalDialogImport"
      :width="600"
      header="Import Pegawai"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="importFile === null"
      text-button="Tambah"
      @handler-button="importHandler"
      @close-modal="modalDialogImport = false"
      @close-button="modalDialogImport = false"
    >
      <template
        v-slot:body
      >
        <div>
          <FileInput
            v-model="importFile"
            label="File Import"
            outlined
            dense
            :message="message"
            :disabled="false"
            :prependicon="icons.mdiImageMultipleOutline"
            :accept="accept"
            @input="getFile"
          ></FileInput>
        </div>
      </template>
    </ModalDialog>
    <ModalDialog
      :visible="modalCertificate"
      :hidden-actions="false"
      header="Sertifikat Guru"
      @close="modalCertificate = false"
      @close-modal="modalCertificate = false"
    >
      <template v-slot:header>
      </template>
      <template
        v-if="certificates.length"
        v-slot:body
      >
        <div
          v-for="certificate,index in certificates"
          :key="index"
        >
          <div
            class="d-flex py-3 align-center justify-space-between"
          >
            <div>
              <p class="mb-0 font-semibold">
                {{ certificate.name }}
              </p>
              <span>Nomor : {{ certificate.description }}</span>
            </div>
            <div>
              <v-chip
                v-if="checkExtension(certificate.file) === 'pdf'"
                color="success"
                class="v-chip-light-bg success--text"
                :href="certificate.file"
                target="_blank"
              >
                Lihat Sertifikat
              </v-chip>
              <v-chip
                v-if="checkExtension(certificate.file) !== 'pdf'"
                color="success"
                class="v-chip-light-bg success--text"
                @click="showCertificate(certificate.file)"
              >
                Lihat Sertifikat
              </v-chip>
            </div>
          </div>
          <v-divider></v-divider>
        </div>
      </template>
      <template
        v-else
        v-slot:body
      >
        Belum ada sertifikat
      </template>
    </ModalDialog>
    <ImageOverlay
      :visible="showImageCertificate"
      :width="800"
      @close="showImageCertificate = false"
    >
      <template v-slot:body>
        <v-card>
          <v-btn
            class="close-image"
            color="white"
            icon
            @click="closeShowCertificate"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-img
            :src="certificate.file"
            transition="scroll-y-transition"
            min-width="100"
            max-width="700"
          ></v-img>
        </v-card>
      </template>
    </ImageOverlay>
    <ConfirmDialog
      :visible="confirmDialog"
      header="Hapus Pegawai"
      :is-loading-button="isLoadingButton"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
      <template v-slot:body>
        <!-- <p>Apakah anda yakin ingin menghapus data ini?</p> -->
        <p>
          <b>Data yang dihapus termasuk :</b>
        </p>
        <p>
          - Data Guru
          <br>
          - Data Login Pegawai
        </p>
        <p>Seluruh data dari pegawai ini akan dihapus <b>PERMANENT</b>. Silahkan ketik <b>"Delete Employee"</b> untuk validasi penghapusan Pegawai.</p>

        <v-text-field
          v-model="validateDelete"
          label="'Delete Employee'"
          dense
          type="text"
          outlined
          :rules="[validation.required]"
        ></v-text-field>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { integerValidator, required } from '@core/utils/validation'
import { mdiClose, mdiImageMultipleOutline } from '@mdi/js'
import ConfirmDialog from '../../components/ConfirmDialog.vue'
import DataTablePagination from '../../components/DataTablePagination.vue'
import FileInput from '../../components/FileInput.vue'
import ImageOverlay from '../../components/ImageOverlay.vue'
import MainCard from '../../components/MainCard.vue'
import ModalDialog from '../../components/ModalDialog.vue'
import SkeletonLoaderTable from '../../components/SkeletonLoaderTable.vue'
import DataOther from './DataOther.vue'
import DataRequired from './DataRequired.vue'

export default {
  name: 'FunctionaryEmployee',
  components: {
    DataTablePagination,
    SkeletonLoaderTable,
    ModalDialog,
    ImageOverlay,
    ConfirmDialog,
    MainCard,
    DataRequired,
    DataOther,
    FileInput,
  },
  data() {
    return {
      message: 'File .xlsx/.excel, max. 2MB',
      accept: '.xlsx',
      importExport: true,
      validation: {
        required,
        integerValidator,
      },
      tab: '',
      tabs: [{ title: 'Data Wajib' }, { title: 'Data Lainnya (Optional)' }],
      page: 1,
      search: '',
      dialog: null,
      width: 600,
      totalPages: 0,
      totalItems: 0,
      modalDialog: false,
      confirmDialog: false,
      loading: true,
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      isNullRegencies: null,
      isNullDistrict: null,
      isNullVillage: null,
      importFile: null,
      regenciesByProvince: [],
      districtsByRegencies: [],
      villageByDistrict: [],
      overlay: false,
      imageOverlay: false,
      icons: {
        mdiClose,
        mdiImageMultipleOutline,
      },

      formData: null,

      headers: [
        { text: 'No', value: 'index' },
        // { text: 'Nama Lengkap', value: 'employeeNameAndPhoto' },
        { text: 'NIP', value: 'nip' },
        { text: 'Nama Lengkap', value: 'name' },
        { text: 'Jabatan', value: 'status_work' },
        { text: 'Alamat', value: 'place.address' },
        { text: 'Total Sertifikat', value: 'total_certificate' },
        { text: 'Sertifikat', value: 'detail' },
        { text: 'Aksi', value: 'actions' },
      ],
      genders: ['Laki-Laki', 'Perempuan'],
      religions: ['Budha', 'Hindu', 'Islam', 'Kristen Khatolik', 'Kristen Protestan', 'Konghuchu'],
      maritalStatus: ['Kawin', 'Belum Kawin'],
      statusWork: ['Guru', 'Satpam'],
      provinces: [],
      regencies: [],
      districts: [],
      villages: [],
      employees: [],
      updateEmployee: {
        nip: '',
        nik: '',
        email: '',
        date_of_birth: '',
        place_of_birth: '',
        gender: '',
        religion: '',
        marital_status: '',
        phone_house: '',
        phone: '',
        address: '',
        photo: null,
      },
      updateEmployeeReq: {
        name: '',
        username: '',
        password: '',
        status_work: '',
      },
      employee: {
        uuid: '',
        nip: '',
        nik: '',
        name: '',
        first_degree: '',
        las_degree: '',
        date_of_birth: '',
        place_of_birth: '',
        gender: '',
        religion: '',
        marital_status: '',
        phone_house: '',
        phone: '',
        status_work: '',
        province_uuid: '',
        regencies_uuid: '',
        district_uuid: '',
        villages_uuid: '',
        address: '',
        rt: '',
        rw: '',
        zip_code: '',
        latitude: '',
        longitude: '',
        photo: null,
      },
      school_uuid: null,
      school: [],
      modalDialogImport: false,
      modalCertificate: false,
      showImageCertificate: false,
      validateDelete: null,
      certificates: [],
      certificate: {
        file: '',
      },
      filterQuery: {
        employee: '',
      },
      valueCheck: false,
    }
  },
  watch: {
    page: {
      handler() {
        this.listEmployee(this.filterQuery)
      },
    },
  },
  mounted() {
    this.getSchoolUuid()
    this.listEmployee()
  },
  methods: {
    changeValid() {
      this.valueCheck = this.$refs.requiredData.$data.formValid
    },
    async showOverlay(uuid) {
      this.overlay = !this.overlay
      this.employee.uuid = uuid
    },
    showFormImport() {
      this.modalDialogImport = true
    },
    async importHandler() {
      if (this.importFile === null) {
        return this.showSnackbar({
          text: 'Please add file first!!!',
          color: 'error',
        })
      }
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('file', this.importFile)
      await this.$services.ImportServices.import('importemployee', this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Import Employee Has Successfully',
            color: 'success',
          })
          this.importFile = null
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
          this.importFile = null
        },
      )
      this.isLoadingButton = false
      this.modalDialogImport = false
      await this.listEmployee()

      return true
    },
    async exportHandler() {
      this.isLoadingButton = true
      await this.$services.ExportServices.export('exportemployee').then(
        data => {
          this.showSnackbar({
            text: 'Berhasil download template',
            color: 'success',
          })
          const url = window.URL.createObjectURL(new Blob([data.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'template.xlsx')
          document.body.appendChild(link)
          link.click()
          this.isLoadingButton = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
    },
    async showImage(uuid) {
      await this.$services.ApiServices.get('employee', uuid).then(
        ({ data }) => {
          this.employee = data.data
          this.employee.photo = data.data.photo
        },
        err => console.error(err),
      )
      this.imageOverlay = true
    },
    async closeImage() {
      this.overlay = false
      this.imageOverlay = false
    },

    changeListRegencies() {
      this.regenciesByProvince = this.regencies.filter(item => item.province_uuid === this.employee.province_uuid.uuid)
      if (this.regenciesByProvince.length < 1) {
        this.isNullRegencies = 'provinsi yang anda pilih tidak memiliki kabupaten'
      }
    },
    changeListDistrict() {
      this.isNullDistrict = false
      this.districtsByRegencies = this.districts.filter(
        item => item.regencies_uuid === this.employee.regencies_uuid.uuid,
      )
      if (this.districtsByRegencies.length < 1) {
        this.isNullDistrict = 'kabupaten/kota yang anda pilih tidak memiliki kecamatan'
      }
    },
    changeListVillage() {
      this.villageByDistrict = this.villages.filter(item => item.district_uuid === this.employee.district_uuid.uuid)
      if (this.villageByDistrict.length < 1) {
        this.isNullVillage = 'kecamatan yang anda pilih tidak memiliki dusun'
      }
    },

    async listEmployee(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list('employee', { ...params, page: this.page, school: this.school_uuid }).then(
        ({ data }) => {
          this.employees = data.data.map((employees, index) => ({
            ...employees,
            index: index + 1,
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },

    resetForm() {
      this.employee.nip = ''
      this.employee.nik = ''
      this.employee.name = ''
      this.employee.first_degree = ''
      this.employee.las_degree = ''
      this.employee.date_of_birth = ''
      this.employee.place_of_birth = ''
      this.employee.gender = ''
      this.employee.religion = ''
      this.employee.marital_status = ''
      this.employee.phone_house = ''
      this.employee.phone = ''
      this.employee.date_of_work = ''
      this.employee.status_work = ''
      this.employee.province_uuid = ''
      this.employee.regencies_uuid = ''
      this.employee.district_uuid = ''
      this.employee.villages_uuid = ''
      this.employee.address = ''
      this.employee.rt = ''
      this.employee.rw = ''
      this.employee.zip_code = ''
      this.employee.latitude = ''
      this.employee.longitude = ''
      this.employee.photo = null
      this.updateEmployeeReq.name = ''
      this.updateEmployeeReq.username = ''
      this.updateEmployeeReq.password = ''
      this.updateEmployeeReq.status_work = ''
      this.updateEmployee.nip = ''
      this.updateEmployee.nik = ''
      this.updateEmployee.address = ''
      this.updateEmployee.photo = null
      this.updateEmployee.email = ''
      this.updateEmployee.date_of_birth = ''
      this.updateEmployee.place_of_birth = ''
      this.updateEmployee.gender = ''
      this.updateEmployee.religion = ''
      this.updateEmployee.marital_status = ''
      this.updateEmployee.phone_house = ''
      this.updateEmployee.phone = ''
    },

    showFormAdd() {
      this.dialog = 'add'
      this.resetForm()
      this.updateEmployee = {
        nip: '',
        nik: '',
        email: '',
        date_of_birth: '',
        place_of_birth: '',
        gender: '',
        religion: '',
        marital_status: '',
        phone_house: '',
        phone: '',
        address: '',
        photo: null,
      }
      this.updateEmployeeReq = {
        name: '',
        username: '',
        password: '',
        status_work: '',
      }

      this.modalDialog = true
    },

    async add() {
      this.isLoadingButton = true

      this.formData = new FormData()

      let objectNew = []
      if (this.$refs.otherData === undefined) {
        objectNew = { ...this.$refs.requiredData.$data.employee }
        objectNew.is_moderator = objectNew.is_moderator ? 1 : 0
      } else {
        this.$refs.otherData.$data.employee.province_uuid = this.$refs.otherData.$data.employee.province_uuid?.uuid
        this.$refs.otherData.$data.employee.regencies_uuid = this.$refs.otherData.$data.employee.regencies_uuid?.uuid
        this.$refs.otherData.$data.employee.district_uuid = this.$refs.otherData.$data.employee.district_uuid?.uuid
        this.$refs.otherData.$data.employee.villages_uuid = this.$refs.otherData.$data.employee.villages_uuid?.uuid
        objectNew = { ...this.$refs.requiredData.$data.employee, ...this.$refs.otherData.$data.employee }
      }

      Object.keys(objectNew).forEach((key, item) => {
        this.formData.append(key, objectNew[key])
        if (objectNew[key] === '' || objectNew[key] === null || typeof objectNew[key] === 'undefined') {
          this.formData.delete(key)
        }
      })
      if (typeof this.formData.get('is_active') === 'string') {
        this.formData.delete('is_active')
      }
      await this.$services.ApiServices.add('employee', this.formData).then(
        data => {
          this.showSnackbar({
            text: 'Add Employee Has Successfully',
            color: 'success',
          })
          this.listEmployee(this.filterQuery)
          this.isLoadingButton = true
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingButton = false
      this.resetForm()
    },

    getFile(data) {
      this.importFile = data
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: 'you dont have access to school',
            color: 'error',
          })

          return
        }
        this.school_uuid = el.school.uuid
      })

      return true
    },
    async showFormEdit(uuid) {
      this.dialog = 'edit'
      await this.$services.ApiServices.get('employee', uuid).then(
        ({ data }) => {
          this.employee = data.data
          this.updateEmployee = { ...data.data }
          this.updateEmployeeReq = { ...data.data }
          if (this.updateEmployee.image === null) {
            this.updateEmployee.photo = data.data.image
          } else {
            this.updateEmployee.photo = data.data.image.split('/').pop()
          }

          if (this.employee.is_active === 'Active' || this.employee.is_active === 'Aktif') {
            this.updateEmployee.is_active = 1
          } else {
            this.updateEmployee.is_active = 0
          }
          if (this.updateEmployee.place !== null) {
            this.updateEmployee.province_uuid = this.updateEmployee.place?.province
            this.updateEmployee.regencies_uuid = this.updateEmployee.place?.regencies
            this.updateEmployee.district_uuid = this.updateEmployee.place?.district
            this.updateEmployee.villages_uuid = this.updateEmployee.place?.villages

            this.updateEmployee.address = data.data.place.address
          }

          this.modalDialog = true
        },
        err => console.error(err),
      )
    },

    async update(uuid) {
      this.isLoadingButton = true
      let objectNew = []
      if (this.$refs.otherData === undefined) {
        objectNew = { ...this.$refs.requiredData.$data.employee }
        objectNew.is_moderator = objectNew.is_moderator ? 1 : 0
      } else {
        this.$refs.otherData.$data.employee.province_uuid = this.$refs.otherData.$data.employee.province_uuid?.uuid
        this.$refs.otherData.$data.employee.regencies_uuid = this.$refs.otherData.$data.employee.regencies_uuid?.uuid
        this.$refs.otherData.$data.employee.district_uuid = this.$refs.otherData.$data.employee.district_uuid?.uuid
        this.$refs.otherData.$data.employee.villages_uuid = this.$refs.otherData.$data.employee.villages_uuid?.uuid
        objectNew = { ...this.$refs.requiredData.$data.employee, ...this.$refs.otherData.$data.employee }
      }

      this.formData = new FormData()
      Object.keys(objectNew).forEach((key, item) => {
        this.formData.append(key, objectNew[key])
        if (objectNew[key] === '' || objectNew[key] === null || typeof objectNew[key] === 'undefined') {
          this.formData.delete(key)
        }
      })
      if (typeof this.formData.get('is_active') === 'string') {
        this.formData.delete('is_active')
        this.formData.append('is_active', this.employee.is_active ? 1 : 0)
      }
      if (typeof this.formData.get('photo') === 'string') {
        this.formData.delete('photo')
      }
      await this.$services.ApiServices.update('employee', this.formData, this.employee.uuid).then(
        res => {
          this.resetForm()
          this.showSnackbar({
            text: 'Update Employee Has Successfully',
            color: 'success',
          })
        },
        err => console.error(err),
      )
      await this.listEmployee(this.filterQuery)

      this.isLoadingButton = false
      this.modalDialog = false
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    confirmDestroy(uuid) {
      this.validateDelete = null
      this.confirmDialog = true
      this.employee.uuid = uuid
    },

    async destroy() {
      if (this.validateDelete === null || this.validateDelete !== 'Delete Employee') {
        this.showSnackbar({
          text: "Please, type 'Delete Employee' first !!! ",
          color: 'error',
        })

        return
      }
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy('employee', this.employee.uuid).then(
        res => {
          this.listEmployee(this.filterQuery)
          this.showSnackbar({
            text: 'Delete Employee Has Successfully',
            color: 'error',
          })
        },
        err => console.error(err),
      )
      this.isLoadingButton = false
      this.confirmDialog = false
    },
    // exportData() {
    //   this.$services.ApiServices.download('employee').then(({ data }) => {})
    // },
    checkExtension(certificate) {
      const file = certificate.split('.')

      const last = file[file.length - 1]

      return last
    },
    async detailCertificate(uuid) {
      await this.$services.ApiServices.get('employee', uuid).then(({ data }) => {
        this.certificates = data.data.certificate
      })
      this.modalCertificate = true
    },
    async showCertificate(file) {
      this.certificate.file = file
      this.modalCertificate = false
      this.showImageCertificate = true
    },
    async closeShowCertificate() {
      this.overlay = false
      this.showImageCertificate = false
      this.modalCertificate = true
    },
    searchHandler(employee) {
      this.filterQuery.employee = employee
      this.page = 1
      this.listEmployee(this.filterQuery)
    },

    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
  },
}
</script>

<style>
</style>
