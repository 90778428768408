<template>
  <div class="data-other">
    <v-row class="py-5">
      <v-col
        cols="12"
        class="py-0"
      >
        <h3 class="font-weight-bold mb-4">
          Data Diri
        </h3>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-text-field
            v-model="employee.nip"
            label="NIP"
            outlined
            type="number"
            dense
          ></v-text-field>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-text-field
            v-model="employee.nik"
            label="NIK"
            outlined
            type="number"
            dense
          ></v-text-field>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-text-field
            v-model="employee.place_of_birth"
            label="Tempat Lahir"
            outlined
            type="text"
            dense
          ></v-text-field>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-menu
            v-model="date"
            :close-on-content-click="true"
            nudge-right="0"
            nudge-top="20"
            max-width="100%"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="employee.date_of_birth"
                label="Tanggal"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                @click="autofillDate"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="employee.date_of_birth"
              :first-day-of-week="0"
              locale="id-id"
              color="primary"
              @input="date = false"
            ></v-date-picker>
          </v-menu>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-autocomplete
            v-model="employee.gender"
            label="Jenis Kelamin"
            outlined
            :items="genders"
            type="text"
            dense
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-autocomplete
            v-model="employee.marital_status"
            label="Status Pernikahan"
            outlined
            :items="maritalStatus"
            type="text"
            dense
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-autocomplete
            v-model="employee.religion"
            label="Agama"
            outlined
            :items="religions"
            type="text"
            dense
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-text-field
            v-model="employee.email"
            label="Email"
            outlined
            type="email"
            dense
          ></v-text-field>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-text-field
            v-model="employee.phone"
            label="No HP"
            outlined
            type="number"
            dense
          ></v-text-field>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-text-field
            v-model="employee.phone_house"
            label="Telepon Rumah"
            outlined
            type="number"
            dense
          ></v-text-field>
        </div>
      </v-col>
      <v-col
        cols="12"
        class="pt-0 pb-3"
      >
        <FileInput
          v-model="initialFile"
          :value="employee.photo"
          label="Gambar"
          outlined
          dense
          :disabled="false"
          :prependicon="icons.mdiImageMultipleOutline"
          @input="getFile"
        ></FileInput>
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <div>
          <v-text-field
            v-model="employee.address"
            label="Alamat Tinggal"
            outlined
            type="text"
            dense
          ></v-text-field>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="pb-2 pt-0"
      >
        <div>
          <v-autocomplete
            v-model="employee.province_uuid"
            :items="provinces"
            label="Provinsi"
            return-object
            item-text="name"
            item-value="uuid"
            dense
            messages="Pilih provinsi"
            outlined
            @change="changeListRegencies()"
          >
          </v-autocomplete>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="pb-2 pt-0"
      >
        <div>
          <v-autocomplete
            v-model="employee.regencies_uuid"
            :items="regenciesByProvince"
            :loading="loadingRegencies"
            label="Kabupaten/Kota"
            return-object
            item-text="name"
            item-value="uuid"
            dense
            outlined
            :disabled="!regenciesByProvince.length"
            @change="changeListDistrict()"
          >
          </v-autocomplete>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="pb-2 pt-0"
      >
        <div>
          <v-autocomplete
            v-model="employee.district_uuid"
            :items="districtsByRegencies"
            :loading="loadingDistrict"
            label="Kecamatan"
            return-object
            item-text="name"
            item-value="uuid"
            dense
            outlined
            :disabled="!districtsByRegencies.length"
            @change="changeListVillage()"
          >
          </v-autocomplete>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="pb-2 pt-0"
      >
        <div>
          <v-autocomplete
            v-model="employee.villages_uuid"
            :items="villageByDistrict"
            :loading="loadingVillage"
            label="Kelurahan/Desa"
            return-object
            item-text="name"
            item-value="uuid"
            dense
            :disabled="!villageByDistrict.length"
            outlined
          >
          </v-autocomplete>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FileInput from '../../components/FileInput.vue'
import { mdiImageMultipleOutline } from '@mdi/js'

export default {
  name: 'DataOther',
  components: {
    FileInput,
  },
  props: {
    dataemployee: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      icons: {
        mdiImageMultipleOutline,
      },
      employee: {
        nip: '',
        nik: '',
        email: '',
        date_of_birth: '',
        place_of_birth: '',
        gender: '',
        religion: '',
        marital_status: '',
        phone_house: '',
        phone: '',
        address: '',
        photo: '',
      },
      provinces: [],
      province: {},
      regencies: [],
      regency: {},
      loadingRegencies: false,
      loadingDistrict: false,
      loadingVillage: false,
      regenciesByProvince: [],
      isNullRegencies: '',
      districts: [],
      districtsByRegencies: [],
      villageByDistrict: [],
      isNullDistrict: '',
      isNullVillage: '',
      district: {},
      villages: [],
      village: {},
      service: 'village',
      genders: ['Laki-Laki', 'Perempuan'],
      religions: ['Budha', 'Hindu', 'Islam', 'Kristen Khatolik', 'Kristen Protestan', 'Konghuchu'],
      maritalStatus: ['Kawin', 'Belum Kawin'],
      date: false,
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.employee.photo === 'string') {
          return new File([this.employee.photo], this.employee.photo, { type: 'text/plain' })
        }

        return this.employee.photo
      },
      set() {
        return this.employee.photo
      },
    },
  },
  watch: {
    dataemployee() {
      if (this.dataemployee.name !== '') {
        this.assignValue()
        this.changeListRegencies()
        this.changeListDistrict()
        this.changeListVillage()
      }
    },
  },
  async mounted() {
    await this.listProvince()

    if (this.dataemployee.name !== '') {
      this.assignValue()
      this.changeListRegencies()
      this.changeListDistrict()
      this.changeListVillage()
    }
  },
  methods: {
    async listProvince() {
      await this.$services.ApiServices.list('province', { per_page: 'all' }).then(
        ({ data }) => {
          this.provinces = data.data
        },
        err => console.error(err),
      )
    },

    async changeListRegencies() {
      this.loadingRegencies = true
      await this.$services.ApiServices.list('regency', {
        per_page: 'all',
        province_uuid:
          typeof this.employee.province_uuid === 'object'
            ? this.employee.province_uuid.uuid
            : this.employee.province_uuid,
      }).then(
        ({ data }) => {
          this.regenciesByProvince = data.data
        },
        err => console.error(err),
      )
      if (this.regenciesByProvince.length < 1) {
        this.isNullRegencies = 'Provinsi yang anda pilih belum memiliki kabupaten'
      }
      this.loadingRegencies = false
    },
    async changeListDistrict() {
      // this.districtsByRegencies = this.districts.filter(
      //   item => item.regencies_uuid === this.employee.regencies_uuid?.uuid,
      // )
      this.loadingDistrict = true

      await this.$services.ApiServices.list('district', {
        per_page: 'all',
        regencies_uuid: this.employee.regencies_uuid?.uuid,
      }).then(
        ({ data }) => {
          this.districtsByRegencies = data.data
        },
        err => console.error(err),
      )
      if (this.districtsByRegencies.length < 1) {
        this.isNullDistrict = 'Kecamatan yang anda pilih belum memiliki kelurahan'
      }
      this.loadingDistrict = false
    },
    async changeListVillage() {
      this.loadingVillage = true

      // this.villageByDistrict = this.villages.filter(item => item.district_uuid === this.employee.district_uuid?.uuid)
      await this.$services.ApiServices.list('village', {
        per_page: 'all',
        district_uuid: this.employee.district_uuid?.uuid,
      }).then(
        ({ data }) => {
          this.villageByDistrict = data.data
        },
        err => console.error(err),
      )
      if (this.villageByDistrict.length < 1) {
        this.isNullVillage = 'Kelurahan yang anda pilih belum memiliki desa'
      }
      this.loadingVillage = false
    },
    assignValue() {
      this.employee = { ...this.dataemployee }
    },
    getFile(data) {
      this.employee.photo = data
    },
    autofillDate() {
      this.employee.date_of_birth = new Date().toISOString().substr(0, 10)
    },
  },
}
</script>

<style>
</style>
